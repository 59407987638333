<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Detail Dapil</h3>
          </div>
          <div class="panel-body">
            <div class="alert alert-secondary fade show">
              <table>
                <tr>
                  <td style="width: 100px"><strong>Kode Dapil</strong></td>
                  <td>:</td>
                  <td>{{ detail.kode_dapil }}</td>
                </tr>
                <tr>
                  <td><strong>Nama Dapil</strong></td>
                  <td>:</td>
                  <td>{{ detail.nama_dapil }}</td>
                </tr>
              </table>
            </div>
            <div class="row">
              <div class="col-md-6">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center">Kecamatan</th>
                      <th class="text-center">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="kecamatan in listKecamatanDapil"
                      :key="kecamatan.id_kec"
                    >
                      <td>{{ kecamatan.nama }}</td>
                      <td class="text-center" style="width: 75px">
                        <button
                          @click="removeKecamatan(kecamatan.id_wilayah_dapil)"
                          class="btn btn-danger btn-icon btn-circle"
                        >
                          <i class="fa fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <select
                                v-model="form.kecamatan"
                                name="kecamatan"
                                class="form-control"
                                :class="{
                                  'is-invalid': kecValidate.kecamatan,
                                }"
                              >
                                <option value="">Pilih</option>
                                <option
                                  v-for="kecamatan in listKecamatan"
                                  :value="kecamatan.id_kec"
                                  :key="kecamatan.id_kec"
                                >
                                  {{ kecamatan.nama_kecamatan }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <button
                              class="btn btn-block btn-info"
                              @click="addKecamatan"
                              variant="primary"
                            >
                              <i
                                v-if="loadingKecamatan"
                                class="fas fa-circle-notch fa-spin"
                              ></i>
                              Tambah Kecamatan
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center">Urut</th>
                      <th class="text-center">Nama Caleg</th>
                      <th class="text-center">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="caleg in listCalegDapil"
                      :key="caleg.id_caleg_dapil"
                    >
                      <td class="text-center">{{ caleg.nomor_urut }}</td>
                      <td>{{ caleg.nama_caleg }}</td>
                      <td class="text-center" style="width: 75px">
                        <button
                          @click="removeCaleg(caleg.id_caleg_dapil)"
                          class="btn btn-danger btn-icon btn-circle"
                        >
                          <i class="fa fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3">
                        <div class="row">
                          <div class="col-md-2">
                            <div class="form-group">
                              <input
                                type="number"
                                v-model="caleg.urut"
                                placeholder="No. Urut"
                                class="form-control"
                                :class="{ 'is-invalid': calegValidate.urut }"
                              />
                            </div>
                          </div>
                          <div class="col-md-7">
                            <div class="form-group">
                              <input
                                type="text"
                                v-model="caleg.nama_caleg"
                                placeholder="Nama Caleg"
                                class="form-control"
                                :class="{
                                  'is-invalid': calegValidate.nama_caleg,
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <button
                              class="btn btn-block btn-info"
                              @click="addCaleg"
                              variant="primary"
                            >
                              <i
                                v-if="loadingCaleg"
                                class="fas fa-circle-notch fa-spin"
                              ></i>
                              Tambah Caleg
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  data() {
    return {
      detail: {
        id_dapil: "",
        kode_dapil: "",
        nama_dapil: "",
      },
      form: {
        kecamatan: "",
      },
      caleg: {
        urut: "",
        nama_caleg: "",
      },
      loading: false,
      loadingKecamatan: false,
      loadingCaleg: false,
      showUserAccess: true,
      kecValidate: [],
      calegValidate: [],
      listKecamatanDapil: [],
      listKecamatan: [],
      listCalegDapil: [],
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.detail.id_dapil = id;
      this.fetchData();
    } else {
      this.detail.id_dapil = "";
    }
    this.fetchKecamatan();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/dapil/detail/" + this.detail.id_dapil)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }

          this.fetchKecamatanDapil();
          this.fetchCalegDapil();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchKecamatan() {
      axios
        .get("/v1/daerah/kecamatan/7209", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchKecamatanDapil() {
      axios
        .get("/v1/dapil/kecamatan/" + this.detail.kode_dapil, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKecamatanDapil = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchCalegDapil() {
      axios
        .get("/v1/dapil/caleg/" + this.detail.kode_dapil, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listCalegDapil = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    addKecamatan() {
      this.loadingKecamatan = true;
      const formData = new FormData();
      formData.append("dapil", this.detail.kode_dapil);
      formData.append("kecamatan", this.form.kecamatan);

      axios
        .post("/v1/dapil/kecamatan/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingKecamatan = false;
            this.kecValidate = response.data.message;
          } else {
            this.loadingKecamatan = false;
            this.fetchKecamatanDapil();
            this.resetFormKecamatan();
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    removeKecamatan(params) {
      try {
        axios
          .get("/v1/dapil/remove_kecamatan/" + params)
          .then((response) => {
            if (response.status === 200) {
              this.fetchKecamatanDapil();
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    addCaleg() {
      this.loadingCaleg = true;
      const formData = new FormData();
      formData.append("dapil", this.detail.kode_dapil);
      formData.append("urut", this.caleg.urut);
      formData.append("nama_caleg", this.caleg.nama_caleg);

      axios
        .post("/v1/dapil/caleg/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingCaleg = false;
            this.calegValidate = response.data.message;
          } else {
            this.loadingCaleg = false;
            this.fetchCalegDapil();
            this.resetFormCaleg();
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    removeCaleg(params) {
      try {
        axios
          .get("/v1/dapil/remove_caleg/" + params)
          .then((response) => {
            if (response.status === 200) {
              this.fetchCalegDapil();
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    resetFormCaleg() {
      this.caleg.urut = "";
      this.caleg.nama_caleg = "";
      this.calegValidate = [];
    },
    resetFormKecamatan() {
      this.form.kecamatan = "";
      this.kecValidate = [];
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
  },
};
</script>
